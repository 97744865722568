/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020,2021
 *
 *==================================================
 */
//Standard libraries
import React, { useRef } from "react";
import { useNavigate } from "react-router";
import { ClickAwayListener } from "@material-ui/core";
//style
import {
  StyledBox,
  StyledButton,
  StyledTypography,
  StyledPopper,
} from "@hcl-commerce-store-sdk/react-component";
//custom
import AllCategoriesExpandedMenu from "./AllCategoriesExpandedMenu";
import ExpandedMenuItem from "./ExpandedMenuItem";

const MenubarMenuItem = (props) => {
  const navigate = useNavigate();
  const { page, selectMenuItem, selectedMenuItem } = props;
  const popperRef: React.RefObject<HTMLButtonElement> =
    useRef<HTMLButtonElement>(null);
  const handleClickAway = (event) => {
    const target = event.target;
    if (
      popperRef.current &&
      (popperRef.current.contains(target) || popperRef.current === target)
    ) {
      return;
    }
    if (selectedMenuItem.id === page.id) {
      selectMenuItem(null);
    }
  };

  const handleMouseLeave = () => {
    selectMenuItem(null);
  };

  const POPPER_ID = `MENU_POPPER_${page.id}`;
  const setWidth = (data) => {
    let { width } = data.offsets.reference;
    if (width < 200) {
      width = 200;
    }
    if (width > data.popper.width) {
      data.styles.width = width;
    }
    return data;
  };

  return (
    <StyledBox position="relative" onMouseLeave={handleMouseLeave}>
      <StyledBox
        p={2}
        mr={1}
        ml={1}
        className={
          selectedMenuItem?.id === page.id
            ? "expanded-menu-hover"
            : "expanded-menu"
        }
        ref={popperRef}>
        <StyledButton
          testId={`header-${page.id}`}
          variant="text"
          onMouseOver={(e) => {
            if (selectedMenuItem === null || selectedMenuItem.id !== page.id) {
              selectMenuItem(page.id);
            }
          }}
          onClick={(e) => {
            if (selectedMenuItem === null || selectedMenuItem.id !== page.id) {
              selectMenuItem(page.id);
            } else {
              if (page.seo?.href) {
                navigate(page.seo?.href);
              }
            }
          }}>
          <StyledTypography
            className="expanded-menu-text"
            variant="body1"
            data-testid="menubar-menuitem-button">
            {page.name}
          </StyledTypography>
        </StyledButton>
      </StyledBox>
      {page.children && (
        <StyledPopper
          id={POPPER_ID}
          data-testid={POPPER_ID}
          open={selectedMenuItem !== null && selectedMenuItem.id === page.id}
          anchorEl={popperRef.current}
          placement="bottom-start"
          disablePortal
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent",
            },
            hide: {
              enabled: false,
            },
            updateWidth: {
              enabled: true,
              order: 875,
              fn: setWidth,
            },
          }}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledBox>
              {page.id === "allCategories" ? (
                <AllCategoriesExpandedMenu
                  pages={page.children}></AllCategoriesExpandedMenu>
              ) : (
                <ExpandedMenuItem page={page}></ExpandedMenuItem>
              )}
            </StyledBox>
          </ClickAwayListener>
        </StyledPopper>
      )}
    </StyledBox>
  );
};
export default MenubarMenuItem;
