import { useEffect } from "react";
import { useLocation } from "react-router-dom";
//Foundation libraries
import { CURRENT_USER } from "../../../_foundation/constants/common";
//Redux
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";

declare let _conv_page_type: string;
declare let _conv_category_id: string;
declare let _conv_category_name: string;
declare let _conv_product_sku: string;
declare let _conv_product_name: string;
declare let _conv_product_price: string;
declare let _conv_customer_id: string;
declare let _conv_custom_v1: string;
declare let _conv_custom_v2: string;
declare let _conv_custom_v3: string;
declare let _conv_custom_v4: string;

const ConvertScript = ({ mySite }) => {
  const location: any = useLocation();

  function updatePageType(val: string) {
    _conv_page_type = val;
  }
  function updateCategoryId(val: string) {
    _conv_category_id = val;
  }
  function updateCategoryName(val: string) {
    _conv_category_name = val;
  }
  function updateProductSku(val: string) {
    _conv_product_sku = val;
  }
  function updateProductName(val: string) {
    _conv_product_name = val;
  }
  function updateProductPrice(val: string) {
    _conv_product_price = val;
  }
  function updateCustomerId(val: string) {
    _conv_customer_id = val;
  }
  function updateCustomV1(val: string) {
    _conv_custom_v1 = val;
  }
  function updateCustomV2(val: string) {
    _conv_custom_v2 = val;
  }
  function updateCustomV3(val: string) {
    _conv_custom_v3 = val;
  }
  function updateCustomV4(val: string) {
    _conv_custom_v4 = val;
  }
  function resetConvertPageVars() {
    _conv_page_type = "";
    _conv_category_id = "";
    _conv_category_name = "";
    _conv_product_sku = "";
    _conv_product_name = "";
    _conv_product_price = "";
    _conv_custom_v2 = "";
    _conv_custom_v3 = "";
    _conv_custom_v4 = "";
  }
  function resetConvertCustomerVars() {
    _conv_customer_id = "";
    _conv_custom_v1 = "";
  }
  function addOrTogglePageClass(element, className) {
    if (
      className != null &&
      className !== "" &&
      !element.classList.contains(className)
    ) {
      element.classList.remove("HomePage");
      element.classList.remove("CategoryPage");
      element.classList.remove("ProductListingPage");
      element.classList.remove("ProductPage");
      element.classList.remove("ItemPage");
      element.classList.remove("SearchPage");
      element.classList.add(className);
    } else if (
      className == null ||
      (className === "" && !element.classList.contains(className))
    ) {
      element.classList.remove("HomePage");
      element.classList.remove("CategoryPage");
      element.classList.remove("ProductListingPage");
      element.classList.remove("ProductPage");
      element.classList.remove("ItemPage");
      element.classList.remove("SearchPage");
    }
  }

  useEffect(() => {
    resetConvertPageVars();
    resetConvertCustomerVars();
    const rootElement = window.document.getElementById("root");
    if (location.pathname === "/") {
      updatePageType("HomePage");
      addOrTogglePageClass(rootElement, "HomePage");
    } else {
      addOrTogglePageClass(rootElement, null);
    }
    const currentUser = localStorageUtil.get(CURRENT_USER);
    if (currentUser) {
      const companyName = currentUser?.details?.companyName;
      const { userId, userLoggedIn } = currentUser;
      if (userLoggedIn) {
        updateCustomerId(userId);
        if (companyName != null && companyName !== "") {
          updateCustomV1("B2B");
        } else {
          updateCustomV1("B2C");
        }
      } else {
        updateCustomerId("");
        updateCustomV1("");
      }
    } else {
      updateCustomerId("");
      updateCustomV1("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};

export default ConvertScript;
