/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
//Custom libraries
import MiniCartPopperContent from "./MiniCartPopperContent";
//UI
import { StyledAccountPopper } from "@hcl-commerce-store-sdk/react-component";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import { XS_MOBILE_W } from "../../constants/common";

interface MiniCartProps {
  id: string;
  open: boolean;
  handleClose: (e?: any) => void;
}

/**
 * MiniCart component
 * displays number of cart items icon
 * @param props
 */
const MiniCart = React.forwardRef<HTMLButtonElement | null, MiniCartProps>(
  (props: any, ref: any) => {
    const { id, open, handleClose } = props;
    const [arrowRef, setArrowRef] = useState<any>(null);
    const { w } = useWinDimsInEM();
    const xSmall = XS_MOBILE_W;
    const offset = w <= xSmall ? { offset: "-48" } : undefined;

    return (
      <>
        {/*<StyledButton
          testId="header-mini-cart-button"
          ref={ref}
          className="header-actionsButton"
          variant="text"
          color="secondary"
          onClick={handleClick}>
          <StyledHeaderActions>
            <ShoppingCartIcon />
          </StyledHeaderActions>
    </StyledButton>*/}

        <StyledAccountPopper
          id={id}
          open={open}
          anchorEl={ref?.current}
          onClose={handleClose}
          placement="bottom-end"
          modifiers={{
            offset,
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent",
            },
            hide: {
              enabled: true,
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          className="mini-cart-popper">
          <span className="arrow" ref={setArrowRef} />
          <MiniCartPopperContent handleClose={handleClose} />
        </StyledAccountPopper>
      </>
    );
  }
);

export default MiniCart;
