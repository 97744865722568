/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const appConfig = {
  page: {
    name: "app",
  },
  espot: {
    tumblerESpot: "GaletonHeaderBanner",
    propWarningESpot: "CaliforniaProp65Warning",
    sizingGuideESpot: "SizingGuideContent",
    satisfactionGuaranteeBadge: "SatisfactionGuaranteeBadge",
    privacyPolicyESpot: "PrivacyPolicyContent",
    salesTermsESpot: "SalesTermsContent",
    termsOfUseESpot: "TermsOfUseContent",
    ourCommitmentESpot: "OurCommitmentContent",
    productSizingESpot: "ProductSizingContent",
    signInAlertESpot: "SignInAlert",
    californiaShippingAddressESpot: "CaliforniaShippingAddress",
  },
};
