/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { ROUTE_CONFIG } from "../../configs/routes";
import {
  REGISTER_PROTECTED,
  GENERIC_PROTECTED,
  HOME,
  DASHBOARD,
} from "../../constants/routes";
import {
  EMPTY_STRING,
  INVALID_EMAIL,
  INVALID_PHONE,
} from "../../constants/common";
//Redux
import {
  loginStatusSelector,
  userEmailSelector,
  userPhoneSelector,
  userAddressSelector,
  userInitStatusSelector,
} from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { sessionStorageUtil } from "../../_foundation/utils/storageUtil";

const LoginGuard: React.FC = () => {
  const { mySite } = useSite();
  const location: any = useLocation();
  const navigate = useNavigate();
  const loginStatus = useSelector(loginStatusSelector);
  const userInited = useSelector(userInitStatusSelector);
  const userEmail = useSelector(userEmailSelector);
  const userPhone = useSelector(userPhoneSelector);
  const userAddress = useSelector(userAddressSelector);
  const addressDetails = useSelector(addressDetailsSelector);

  const emailId = addressDetails?.email1 ? addressDetails?.email1 : userEmail;
  const phoneNumber = addressDetails?.phone1
    ? addressDetails?.phone1
    : userPhone;
  const address = addressDetails?.addressLine?.[0]
    ? addressDetails?.addressLine?.[0]
    : userAddress;

  useEffect(() => {
    if (mySite && userInited) {
      const routes = mySite.isB2B ? ROUTE_CONFIG.B2B : ROUTE_CONFIG.B2C;
      const branch = matchRoutes(routes, location.pathname);
      let protectedRoute;
      if (!loginStatus) {
        protectedRoute = branch?.some((b) => {
          return b.route["isProtected"] === REGISTER_PROTECTED;
        });
      } else {
        protectedRoute = branch?.some((b) => {
          return b.route["isProtected"] === GENERIC_PROTECTED;
        });

        // registered user
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!protectedRoute) {
          if (
            userEmail === INVALID_EMAIL ||
            userPhone === INVALID_PHONE ||
            userAddress === EMPTY_STRING ||
            userEmail === EMPTY_STRING ||
            userPhone === EMPTY_STRING
          ) {
            sessionStorageUtil.set("redirectHome", 1);
            navigate(DASHBOARD);
          } else {
            sessionStorageUtil.remove("redirectHome");
            sessionStorageUtil.set("redirectHome", 0);
            const redirectRoute = location?.state?.redirectRoute;
            if (redirectRoute) {
              navigate(redirectRoute);
            } else {
              navigate(HOME);
            }
          }
        } else if (
          (emailId === INVALID_EMAIL ||
            phoneNumber === INVALID_PHONE ||
            address === EMPTY_STRING ||
            emailId === EMPTY_STRING ||
            phoneNumber === EMPTY_STRING) &&
          location.pathname !== DASHBOARD
        ) {
          sessionStorageUtil.set("redirectHome", 1);
          navigate(DASHBOARD);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    loginStatus,
    userEmail,
    mySite,
    userInited,
    emailId,
    phoneNumber,
    address,
  ]);

  return <></>;
};

export default LoginGuard;
