/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const TOP_CATEGORIES_DEPTH_LIMIT = "25,11";

export const PRODUCT_LIST_FIELDS = "facetView";

export const KEYWORD_LIMIT = 4;

export const PAGINATION_CONFIGS = {
  pageLimit: 24,
  pageDefaultOffset: 0, //The page offset to start with on pageload and filters
};

export const IMAGE_RES = {
  thumbnail: "thumbnail-sr",
  thumbnail2x: "thumbnail-hr",
  fullImage: "large-sr",
  fullImage2x: "large-hr",
  pdpThumbnail: "thumbnail-pdp-sr",
  pdpThumbnail2x: "thumbnail-pdp-hr",
};
