/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Redux
import { EMPTY_STRING } from "../../constants/common";
import { RootReducerState } from "../reducers";

const loginStatusSelector = (state: RootReducerState) => {
  return state.user.userLoggedIn || false;
};

const loginErrorMsgSelector = (state: RootReducerState) => {
  return state.user.userLoginErrorMsg;
};
const guestStatusSelector = (state: RootReducerState) => {
  return state.user.isGuest || false;
};
const rememberMeSelector = (state: RootReducerState) => {
  return state.user.rememberMe || false;
};
const wcTokenSelector = (state: RootReducerState) => {
  return state.user.WCToken;
};
const wcTrustedTokenSelector = (state: RootReducerState) => {
  return state.user.WCTrustedToken;
};

const logonIdSelector = (state: RootReducerState) => {
  return state.user.details?.logonId || "";
};

const userIdSelector = (state: RootReducerState) => {
  return state.user.userId;
};

const forUserIdSelector = (state: RootReducerState) => {
  return state.user.forUserId;
};

const userNameSelector = (state: RootReducerState) => {
  const firstName = state.user.details?.firstName || "";
  const lastName = state.user.details?.lastName || "";
  return { firstName, lastName };
};

const userEmailSelector = (state: RootReducerState) => {
  return state.user.details?.email1 || null;
};

const userPhoneSelector = (state: RootReducerState) => {
  return state.user.details?.phone1 || null;
};

const userAddressSelector = (state: RootReducerState) => {
  if (state.user.details) {
    const addressLine = state.user.details.addressLine;
    const city = state.user.details.city || null;
    const userState = state.user.details.state || null;
    const zipCode = state.user.details.zipCode || null;
    if (addressLine && city && userState && zipCode) {
      const addressLine1 =
        state.user.details.addressLine.length > 0
          ? state.user.details?.addressLine[0]
          : EMPTY_STRING;
      if (
        addressLine1 !== EMPTY_STRING &&
        city !== EMPTY_STRING &&
        userState !== EMPTY_STRING &&
        zipCode !== EMPTY_STRING
      ) {
        return `${addressLine1}, ${city}, ${userState} ${zipCode}`;
      } else {
        return EMPTY_STRING;
      }
    } else {
      return EMPTY_STRING;
    }
  } else {
    return null;
  }
};

const userStipendSelector = (state: RootReducerState) => {
  const stipendType = state.user.details?.ext_stipendType;
  const stipendN = state.user.details?.ext_stipendN;
  const stipendX = state.user.details?.ext_stipendX;
  const stipendNextResetDate = state.user.details?.ext_stipendNextResetDate;
  const stipendHidePrices =
    typeof state.user.details?.ext_stipendHidePrices !== "undefined"
      ? state.user.details?.ext_stipendHidePrices
      : false;
  return {
    stipendType: stipendType,
    stipendN: stipendN,
    stipendX: stipendX,
    stipendNextResetDate: stipendNextResetDate,
    stipendHidePrices: stipendHidePrices,
  };
};

const userInitStatusSelector = (state: RootReducerState) => {
  return state.user.initiatedFromStorage;
};

const userLastUpdatedSelector = (state: RootReducerState) => {
  return state.user.lastUpdated;
};

const registrationStatusSelector = (state: RootReducerState) => {
  return state.user.userRegistration || false;
};

export {
  loginStatusSelector,
  loginErrorMsgSelector,
  guestStatusSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
  logonIdSelector,
  registrationStatusSelector,
  userNameSelector,
  userEmailSelector,
  userPhoneSelector,
  userAddressSelector,
  userStipendSelector,
  userIdSelector,
  userInitStatusSelector,
  userLastUpdatedSelector,
  forUserIdSelector,
  rememberMeSelector,
};
