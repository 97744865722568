/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { paramCase } from "change-case";
//Foundation libraries
//import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { footerConfig } from "./footerConstant";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
import {
  CONTACT_US,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  SALES_TERMS,
  PRODUCT_SIZING,
  OUR_COMMITMENT,
  CONTACT_US_RESELLER,
} from "../../constants/routes";

import {
  StyledGrid,
  StyledTypography,
  StyledFooter,
} from "@hcl-commerce-store-sdk/react-component";

function Footer(props: any) {
  const { t } = useTranslation();

  useEffect(() => {
    const digicertScript = document.createElement("script");
    digicertScript.src = "//seal.digicert.com/seals/cascade/seal.min.js";
    document.body.appendChild(digicertScript);

    return () => {
      if (digicertScript.parentNode === document.body) {
        document.body.removeChild(digicertScript);
      }
    };
  }, []);

  const Credit = () => {
    return (
      <div className="footer-credit-logo">
        <ContentRecommendationWidget
          {...{
            widget: {
              id: `footer-${paramCase(footerConfig.espot.creditESpot)}`,
              widgetName: "content-recommendation-widget",
              name: footerConfig.espot.creditESpot,
              properties: {
                emsName: footerConfig.espot.creditESpot,
              },
            },
            page: { name: "" },
          }}></ContentRecommendationWidget>
      </div>
    );
  };

  const Copyright = () => {
    return (
      <div className="copyright">
        <StyledTypography variant="caption">
          {t("Footer.Copyright")}
        </StyledTypography>
      </div>
    );
  };

  return (
    <>
      <StyledFooter>
        <StyledGrid container justifyContent="center">
          <StyledGrid item className="links">
            <StyledGrid container className="footer-top">
              <div>
                <div>
                  <StyledGrid
                    container
                    direction="row"
                    className="footer-links"
                    justifyContent="center">
                    <StyledGrid item>
                      <a
                        key="careers"
                        rel="noreferrer"
                        href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=1dc8deec-7ac9-46d8-9a9e-a46a1494ccda&ccId=9200107056549_2&lang=en_US"
                        target="_blank">
                        {t("Footer.CompanyInformation.Careers")}
                        <span className={"link-entry"}>{"|"}</span>
                      </a>
                    </StyledGrid>
                    <StyledGrid item>
                      <Link key="our-commitment" to={OUR_COMMITMENT}>
                        {t("Footer.CompanyInformation.Commitment")}
                        <StyledTypography
                          variant="span"
                          className={"link-entry"}>
                          {"|"}
                        </StyledTypography>
                      </Link>
                    </StyledGrid>
                    <StyledGrid item>
                      <Link key="contact-us" to={CONTACT_US}>
                        {t("Footer.CustomerSupport.ContactUs")}
                        <StyledTypography
                          variant="span"
                          className={"link-entry"}>
                          {"|"}
                        </StyledTypography>
                      </Link>
                    </StyledGrid>
                    <StyledGrid item>
                      <Link key="reseller-info" to={CONTACT_US_RESELLER}>
                        {t("Footer.CustomerSupport.ResellerInfo")}
                        <StyledTypography
                          variant="span"
                          className={"link-entry"}>
                          {"|"}
                        </StyledTypography>
                      </Link>
                    </StyledGrid>
                    <StyledGrid item>
                      <Link key="product-sizing" to={PRODUCT_SIZING}>
                        {t("Footer.CompanyInformation.Sizing")}
                        <StyledTypography
                          variant="span"
                          className={"link-entry"}>
                          {"|"}
                        </StyledTypography>
                      </Link>
                    </StyledGrid>
                    <StyledGrid item>
                      <Link key="terms-of-use" to={TERMS_OF_USE}>
                        {t("Footer.CustomerSupport.TermsOfUse")}
                        <StyledTypography
                          variant="span"
                          className={"link-entry"}>
                          {"|"}
                        </StyledTypography>
                      </Link>
                    </StyledGrid>
                    <StyledGrid item>
                      <Link key="privacy-policy" to={PRIVACY_POLICY}>
                        {t("Footer.CompanyInformation.PrivacyPolicy")}
                        <StyledTypography
                          variant="span"
                          className={"link-entry"}>
                          {"|"}
                        </StyledTypography>
                      </Link>
                    </StyledGrid>
                    <StyledGrid item>
                      <Link key="sales-terms" to={SALES_TERMS}>
                        {t("Footer.CompanyInformation.SalesTerms")}
                        <StyledTypography
                          variant="span"
                          className={"link-entry-last"}></StyledTypography>
                      </Link>
                    </StyledGrid>
                  </StyledGrid>
                </div>
                <div>
                  <StyledGrid>
                    <StyledTypography className="footer-text" variant="body1">
                      {`Galeton - ${t("Footer.Address")}`}
                    </StyledTypography>
                  </StyledGrid>
                </div>
                <div>
                  <StyledGrid>
                    <StyledTypography className="footer-text" variant="body1">
                      {`Phone: ${t("Footer.PhoneNumber")}  (In North America)`}
                    </StyledTypography>
                  </StyledGrid>
                </div>
                <div>
                  <StyledGrid>
                    <StyledTypography className="footer-text" variant="body1">
                      {`Fax: ${t("Footer.FaxNumber")} (In North America)`}
                    </StyledTypography>
                  </StyledGrid>
                </div>
              </div>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
        <StyledGrid container className="footer-bottom">
          <StyledGrid item xs={12}>
            <div id="DigiCertClickID__TX23SkC"></div>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <Credit />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <Copyright />
          </StyledGrid>
        </StyledGrid>
      </StyledFooter>
      <SessionErrorDialog />
      <ConfirmationDialog />
    </>
  );
}

export default React.memo(Footer);
