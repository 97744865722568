import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  cartSelector,
  orderItemsSelector,
} from "../../../redux/selectors/order";
import * as ROUTES from "../../../constants/routes";
import AsyncCall from "../../../_foundation/gtm/async.service";

const UpdateCartInGTM = ({ mySite }) => {
  const cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);
  const location: any = useLocation();
  const [cartPhase, setCartPhase] = useState<any>("SHOPPING");

  // update shipping phase in GTM layer
  useEffect(() => {
    if (location.pathname === ROUTES.CHECKOUT_SHIPPING) {
      setCartPhase("SHIPPING");
    }
    //   location.pathname === ROUTES.CHECKOUT_SHIPPING ||
    if (location.pathname === ROUTES.CHECKOUT_PAYMENT) {
      setCartPhase("PAYMENT");
    }
    if (location.pathname === ROUTES.CHECKOUT_REVIEW) {
      setCartPhase("REVIEW");
    }
    if (
      location.pathname === ROUTES.ORDER_CONFIRMATION &&
      cart?.orderStatus === "c"
    ) {
      setCartPhase("ORDERCONFIRMED");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // update cart info in GTM dataLayer
  useEffect(() => {
    if (mySite && cart) {
      if (mySite.enableGA) {
        if (mySite.enableGA4 && !mySite.enableUA) {
          //console.log("update dataLayer cart data");
          AsyncCall.updateCartData(cart, cartPhase, {
            enableUA: mySite.enableUA,
            enableGA4: mySite.enableGA4,
          });
        }
      }
    }
  }, [mySite, cart, cartPhase]);

  // update order item info in GTM dataLayer
  useEffect(() => {
    if (mySite && orderItems) {
      if (mySite.enableGA) {
        if (mySite.enableGA4 && !mySite.enableUA) {
          //update dataLayer order items data
          AsyncCall.updateOrderItemsData(orderItems, mySite?.imagePath, {
            enableUA: mySite.enableUA,
            enableGA4: mySite.enableGA4,
          });
        }
      }
    }
  }, [mySite, orderItems]);

  // update cart info in GTM dataLayer
  useEffect(() => {
    if (mySite) {
      if (mySite.enableGA) {
        if (mySite.enableGA4 && !mySite.enableUA) {
          //console.log("update dataLayer cart data");
          AsyncCall.updateCartPhase(cartPhase, {
            enableUA: mySite.enableUA,
            enableGA4: mySite.enableGA4,
          });
        }
      }
    }
  }, [mySite, cartPhase, cart]);
  return null;
};

export default UpdateCartInGTM;
