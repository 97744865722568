import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ApplyUrlPromoCode = ({ mySite }) => {
  const location: any = useLocation();

  function setCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      document.cookie = "url_promo_code=" + code + ";path=/";
    }
  }

  useEffect(() => {
    setCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};

export default ApplyUrlPromoCode;
