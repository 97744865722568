/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  RefObject,
} from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { paramCase } from "change-case";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import categoryService from "../../_foundation/apis/search/categories.service";
import eSpotService from "../../_foundation/apis/transaction/eSpot.service";
import { LOCALE } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";

//Custom libraries
import { headerConfig } from "./headerConstant";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import { MINICART_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import FormattedPriceDisplay from "../widgets/formatted-price-display";
import MiniCart from "./MiniCart";
import MegaMenu from "./MegaMenu";
import ExpandedMenu from "./ExpandedMenu";
import { SearchBar } from "../widgets/search-bar";
import AccountPopperContent from "./AccountPopperContent";

//Redux
import {
  userNameSelector,
  loginStatusSelector,
  userStipendSelector,
} from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { ORG_SWITCH_ACTION } from "../../redux/actions/organization";
import { CONTRACT_SWITCH_ACTION } from "../../redux/actions/contract";
import { LOGOUT_REQUESTED_ACTION } from "../../redux/actions/user";
import { UPDATE_CATEGORIES_STATE_ACTION } from "../../redux/actions/category";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { successSelector } from "../../redux/selectors/success";
import { SuccessMessageReducerState } from "../../redux/reducers/reducerStateInterface";
//UI
import { useTheme } from "@material-ui/core/styles";
import { ClickAwayListener, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import StoreIcon from "@material-ui/icons/Store";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Hidden } from "@material-ui/core";
import {
  StyledAccountPopper,
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledHeaderActions,
  StyledTypography,
  StyledSwipeableDrawer,
  StyledGrid,
  StyledPaper,
  StyledBox,
  PersonIcon,
  StyledList,
  StyledLink,
} from "@hcl-commerce-store-sdk/react-component";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import {
  selectedSellersSelector,
  sellersSelector,
} from "../../redux/selectors/sellers";
import { numItemsSelector, cartSelector } from "../../redux/selectors/order";
import { SELLER_STORAGE_KEY } from "../../constants/common";
import { SET_SELLER_ACTION } from "../../redux/actions/sellers";
import { Sellers } from "./sellers";
import contentEspotUtil from "../../utils/_dival/contentEspotUtil";

interface HeaderProps {
  loggedIn: boolean;
}

const Buttonify = ({ children, ...props }) => {
  const { testId } = props;
  return (
    <StyledButton
      testId={testId}
      className="header-actionsButton"
      variant="text"
      color="secondary"
      {...props}>
      <StyledHeaderActions>{children}</StyledHeaderActions>{" "}
    </StyledButton>
  );
};

const MarketplacePopper = ({ sellerConfig }) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const guestSellerPicker = "guestSellerPicker";
  const { t } = useTranslation();
  const { mySite } = useSite();
  const onClickAway = useCallback((e) => {
    if (e.target.localName === "body") {
      return;
    }
    setOpen(false);
  }, []);

  return !mySite?.isB2B && sellerConfig.showSellerList ? (
    <>
      <div ref={btnRef}>
        <Buttonify testId="header-mp" onClick={setOpen.bind(null, !open)}>
          <StyledBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexWrap="wrap">
            <StoreIcon />
            <StyledTypography variant="body1" component="p">
              {t("sellers.mp")}
            </StyledTypography>
          </StyledBox>
        </Buttonify>
      </div>
      <StyledAccountPopper
        id={guestSellerPicker}
        open={open}
        anchorEl={btnRef.current}
        placement="bottom-end"
        modifiers={{
          flip: { enabled: false },
          preventOverflow: { enabled: true, boundariesElement: "scrollParent" },
          hide: { enabled: false },
        }}
        className="account-popper">
        <ClickAwayListener onClickAway={onClickAway}>
          <StyledPaper className="vertical-padding-1 horizontal-padding-1">
            <StyledList disablePadding>
              <Sellers />
            </StyledList>
          </StyledPaper>
        </ClickAwayListener>
      </StyledAccountPopper>
    </>
  ) : null;
};

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: React.FC<HeaderProps> = (props: any) => {
  const widgetName = getDisplayName(Header);
  const { w } = useWinDimsInEM();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const [myAccountPopperOpen, setMyAccountPopperOpen] =
    useState<boolean>(false);

  const myAccountElRef = useRef<HTMLButtonElement>(null);

  const [miniCartPopperOpen, setMiniCartPopperOpen] = useState<boolean>(false);
  const desktopMiniCartElRef = useRef<HTMLButtonElement>(null);
  const mobileMiniCartElRef = useRef<HTMLButtonElement>(null);
  const [miniCartElRef, setMiniCartElRef] =
    useState<RefObject<HTMLButtonElement>>();

  const { mySite } = useSite();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location: any = useLocation();

  const addressDetails = useSelector(addressDetailsSelector);
  const numItems = useSelector(numItemsSelector);
  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";
  const userName = useSelector(userNameSelector);
  const firstName = addressDetails?.firstName ?? userName?.firstName;
  const lastName = addressDetails?.lastName ?? userName?.lastName;
  const contractId = useSelector(currentContractIdSelector);
  const success: SuccessMessageReducerState = useSelector(successSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const selectedSellers = useSelector(selectedSellersSelector);
  const sellerConfig = useSelector(sellersSelector);
  const sellers = localStorageUtil.get(SELLER_STORAGE_KEY);
  const cart = useSelector(cartSelector);

  const userPreviousLoggedIn = useRef();

  const userStipend = useSelector(userStipendSelector);
  const [stipendEspotName, setStipendEspotName] = useState<string | null>(null);
  const [stipendEspot, setStipendEspot] = useState<any>(null);

  const locale = localStorageUtil.get(LOCALE);

  const isB2B = Boolean(mySite?.isB2B);
  const loggedIn = props.loggedIn;

  /*need to get confirmation on isShoppingEnabled variable truth condition */

  const isShoppingEnabled = !isB2B || isB2B;

  // const isTablet = !useMediaQuery(theme.breakpoints.up("md"));
  // const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const myAccountPopperId = "HEADER_MY_ACCOUNT_Popper";
  const miniCartPopperId = "HEADER_MINI_CART_Popper";
  //const languageTogglePopperId = "HEADER_LANGUAGE_TOGGLE_Popper";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };

  useEffect(() => {
    if (isMobile) {
      setMiniCartElRef(mobileMiniCartElRef);
    } else {
      setMiniCartElRef(desktopMiniCartElRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const [isCheckOut, setIsCheckOut] = useState<boolean>(false);
  const [isCart, setIsCart] = useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname === ROUTES.CHECKOUT ||
      location.pathname === ROUTES.CHECKOUT_SHIPPING ||
      location.pathname === ROUTES.CHECKOUT_PAYMENT ||
      location.pathname === ROUTES.CHECKOUT_REVIEW
    ) {
      setIsCheckOut(true);
    } else {
      setIsCheckOut(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === ROUTES.CART) {
      setIsCart(true);
    } else {
      setIsCart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleMyAccountClick = () => {
    setMyAccountPopperOpen(true);
    setMiniCartPopperOpen(false);
    //setLanguageTogglePopperOpen(false);
  };

  const handleMyAccountPopperClose = () => setMyAccountPopperOpen(false);

  const handleMiniCartClick = () => {
    setMiniCartPopperOpen(true);
    setMyAccountPopperOpen(false);
    //setLanguageTogglePopperOpen(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (
        miniCartElRef &&
        miniCartElRef !== null &&
        miniCartElRef.current !== null
      ) {
        miniCartElRef.current.focus();
      }
    }, 100);
  };

  const handleMiniCartPopperClose = useCallback(
    () => setMiniCartPopperOpen(false),
    []
  );

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const orgId = event.target.value;
    dispatch(
      ORG_SWITCH_ACTION({
        query: { activeOrgId: String(orgId) },
        ...payload,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleContractChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.persist();
    event.preventDefault();
    const conId = event.target.value;
    dispatch(
      CONTRACT_SWITCH_ACTION({
        query: { contractId: String(conId) },
        ...payloadBase,
      })
    );
    navigate(ROUTES.HOME);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const param: any = {
      ...payload,
    };
    dispatch(LOGOUT_REQUESTED_ACTION(param));
  };

  useEffect(() => {
    if (!userLoggedIn && userPreviousLoggedIn.current) {
      setMyAccountPopperOpen(false);
      setMiniCartPopperOpen(false);
      navigate(ROUTES.HOME);
    }
    userPreviousLoggedIn.current = userLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  useEffect(() => {
    if (mySite !== null && contractId !== undefined) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contractId,
        },
        ...payload,
      };
      categoryService
        .getV2CategoryResourcesUsingGET(parameters)
        .then((res) => {
          setTopCategories(res.data.contents);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION(res.data.contents));
        })
        .catch((e) => {
          setTopCategories([]);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION([]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contractId, locale, selectedSellers]);

  useEffect(() => {
    if (success && success.key) {
      if (
        MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key) &&
        !isCart
      ) {
        handleMiniCartClick();
      }
    }
  }, [success]);

  useEffect(() => {
    if (userStipend?.stipendType) {
      setStipendEspotName(`StipendEligibilityType${userStipend.stipendType}`);
    } else if (userStipend?.stipendNextResetDate) {
      setStipendEspotName("StipendEligibilityDate");
    }
  }, [userStipend]);

  useEffect(() => {
    if (stipendEspotName) {
      let substitutionParams: any = null;
      if (userStipend?.stipendType) {
        if (userStipend.stipendType === 3) {
          substitutionParams = [
            {
              "[stipendX]": userStipend.stipendX,
            },
          ];
        } else {
          substitutionParams = [
            { "[stipendX]": userStipend.stipendX },
            { "[stipendN]": userStipend.stipendN },
          ];
        }
      } else if (userStipend?.stipendNextResetDate) {
        substitutionParams = [
          {
            "[stipendNextResetDate]": userStipend.stipendNextResetDate,
          },
        ];
      }
      if (substitutionParams) {
        const parameters: any = {
          storeId: storeID,
          name: stipendEspotName,
          catalogId: catalogID,
          ...payloadBase,
        };

        eSpotService
          .findByName(parameters)
          .then((res) => {
            const eSpotRoot = res.data.MarketingSpotData[0];
            const title = eSpotRoot.marketingSpotDataTitle
              ? eSpotRoot.marketingSpotDataTitle[0]
                  .marketingContentDescription[0].marketingText
              : "";
            const templates = contentEspotUtil.processMarketingSpotData(
              eSpotRoot,
              title,
              substitutionParams,
              payloadBase,
              props,
              dispatch
            );
            setStipendEspot(templates);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stipendEspotName, userStipend.stipendN]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellerConfig.initialized) {
      if (sellers?.length && !sellerConfig.showSellerList) {
        dispatch(SET_SELLER_ACTION({ sellers: null }));
      } else if (sellers?.length && !selectedSellers?.length) {
        dispatch(SET_SELLER_ACTION({ sellers }));
      }
    }
  }, [sellerConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const initOrderTotalSummary = () => {
    let subtotal: number | null = null;
    let subtotalCurrency: string = "";
    let hasStipend: boolean = false;
    let stipendShowFullPrice: boolean | null = null;
    let stipendSubtotal: number | null = null;
    if (cart) {
      try {
        if (cart.orderExtendAttribute) {
          cart.orderExtendAttribute.forEach(function (attr) {
            if (attr.attributeName === "STIPEND_SHOW_FULL_PRICE") {
              if (attr.attributeValue === "true") {
                stipendShowFullPrice = true;
              } else if (attr.attributeValue === "false") {
                stipendShowFullPrice = false;
              }
            } else if (attr.attributeName === "STIPEND_DISPLAY_SUBTOTAL") {
              stipendSubtotal = parseFloat(attr.attributeValue);
            }
          });
        }

        if (
          stipendShowFullPrice !== null &&
          typeof stipendSubtotal === "number"
        ) {
          hasStipend = true;
          subtotal = stipendSubtotal;
        } else {
          subtotal = cart.totalProductPrice
            ? parseFloat(cart.totalProductPrice)
            : null;
        }
        subtotalCurrency = cart.totalProductPriceCurrency;
      } catch (e) {
        console.log("Could not parse cart totals");
      }
    }
    return {
      subtotal,
      subtotalCurrency,
      hasStipend,
      stipendShowFullPrice,
    };
  };

  const { subtotal, subtotalCurrency, hasStipend, stipendShowFullPrice } =
    useMemo(initOrderTotalSummary, [cart]);

  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotName)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotName,
        properties: {
          emsName: headerConfig.espot.eSpotName,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const crwPropsForRightLogo = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotNameSecondLogo)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotNameSecondLogo,
        properties: {
          emsName: headerConfig.espot.eSpotNameSecondLogo,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const crwPropsTollFree = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotNameTollFree)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotNameTollFree,
        properties: {
          emsName: headerConfig.espot.eSpotNameTollFree,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const crwPropsTollFreeMobile = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotNameTollFreeMobile)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotNameTollFreeMobile,
        properties: {
          emsName: headerConfig.espot.eSpotNameTollFreeMobile,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const turnOffSearchBar = () => setShowSearchBar(false);
  const turnOnSearchBar = () => setShowSearchBar(true);
  const toggleOpen = () => setOpen(!open);
  const turnOffOpen = () => setOpen(false);
  const turnOnOpen = () => setOpen(true);

  const checkOutTopBarSection = (
    <StyledGrid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      wrap="nowrap">
      <StyledGrid
        item
        lg={3}
        xs={4}
        className="header-phone"
        data-espotname={headerConfig.espot.eSpotNameTollFree}>
        <ContentRecommendationWidget {...crwPropsTollFree} />
      </StyledGrid>
    </StyledGrid>
  );

  const topBarSection = (
    <StyledGrid container direction="row" alignItems="center" wrap="nowrap">
      <StyledGrid
        item
        lg={6}
        xs={5}
        className="header-phone"
        data-espotname={headerConfig.espot.eSpotNameTollFree}>
        <ContentRecommendationWidget {...crwPropsTollFree} />
      </StyledGrid>
      <StyledGrid item container lg={6} xs={7} justifyContent="flex-end">
        {loggedIn ? (
          <>
            <StyledGrid item className="item-space-signout header-account">
              <StyledTypography
                component="a"
                onClick={(event) => handleLogout(event)}
                href=""
                variant="text">
                {t("Header.AccountPopper.SignOut")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item className="item-space-account header-account">
              <StyledLink
                to={isB2B ? ROUTES.DASHBOARD : ROUTES.ACCOUNT}
                id="head-popper-myaccount_link"
                className="account-link"
                onClick={handleMyAccountPopperClose}>
                <>
                  <StyledTypography variant="text">
                    {isB2B
                      ? t("Dashboard.Title")
                      : t("Header.AccountPopper.AccountSetting")}
                  </StyledTypography>
                </>
              </StyledLink>
            </StyledGrid>
            {isShoppingEnabled && (
              <StyledGrid item className="header-minicart">
                <StyledTypography
                  variant="text"
                  component="p"
                  className="item-space-cart">
                  {t("MiniCart.Items", { count: numItems })}
                </StyledTypography>
                <StyledButton
                  className="header-actionsButton"
                  variant="text"
                  color="secondary"
                  ref={desktopMiniCartElRef}
                  onClick={handleMiniCartClick}>
                  <StyledHeaderActions>
                    <ShoppingCartIcon />
                  </StyledHeaderActions>
                </StyledButton>
              </StyledGrid>
            )}
            {sellerConfig?.showSellerList ? (
              <MarketplacePopper {...{ sellerConfig }} />
            ) : null}
          </>
        ) : (
          <>
            <StyledButton
              className="header-actionsButton"
              variant="text"
              component={Link}
              to={ROUTES.SIGNIN}
              color="secondary"
              testId="sign-in-button">
              <StyledHeaderActions>
                <StyledTypography variant="text">
                  {t("Header.Actions.SignIn")}
                </StyledTypography>
              </StyledHeaderActions>
            </StyledButton>

            {isShoppingEnabled && numItems >= 1 && (
              <StyledGrid item className="header-minicart">
                <StyledTypography
                  variant="text"
                  component="p"
                  className="item-space-cart">
                  {t("MiniCart.Items", { count: numItems })}
                </StyledTypography>
                <StyledTypography variant="text" className="item-space-price">
                  <FormattedPriceDisplay
                    min={subtotal}
                    currency={subtotalCurrency}
                  />
                </StyledTypography>
                <StyledButton
                  className="header-actionsButton"
                  variant="text"
                  color="secondary"
                  ref={desktopMiniCartElRef}
                  onClick={handleMiniCartClick}>
                  <StyledHeaderActions>
                    <ShoppingCartIcon />
                  </StyledHeaderActions>
                </StyledButton>
              </StyledGrid>
            )}
          </>
        )}
      </StyledGrid>
    </StyledGrid>
  );

  return (
    <>
      <StyledHeader>
        {!isCheckOut && (
          <MiniCart
            id={miniCartPopperId}
            open={miniCartPopperOpen}
            handleClose={handleMiniCartPopperClose}
            ref={miniCartElRef}
          />
        )}
        <Hidden xsDown>
          <StyledBox className="header-topBar">
            <StyledContainer>
              {isCheckOut ? checkOutTopBarSection : topBarSection}
            </StyledContainer>
          </StyledBox>
        </Hidden>
        <StyledContainer>
          <StyledGrid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            className="middle-content">
            <StyledGrid item alignItems="center">
              <StyledGrid
                className="header-topbarSection"
                container
                alignItems="center"
                wrap="nowrap">
                {!isCheckOut && (
                  <Hidden mdUp>
                    <StyledGrid item>
                      <StyledBox marginRight={2}>
                        <button
                          className="menu-hamburger"
                          data-testid="menu-hamburger-element"
                          onClick={toggleOpen}>
                          <MenuIcon />
                        </button>
                      </StyledBox>
                    </StyledGrid>
                  </Hidden>
                )}
                {mySite != null && (
                  <StyledGrid
                    data-espotname={headerConfig.espot.eSpotName}
                    item
                    alignItems="center"
                    className="header-branding">
                    <ContentRecommendationWidget {...crwProps} />
                  </StyledGrid>
                )}
              </StyledGrid>
            </StyledGrid>
            {!isCheckOut && (
              <Hidden xsDown>
                <StyledGrid item className="desktop-search-bar">
                  <SearchBar
                    showSearchBar={showSearchBar}
                    closeSearchBar={turnOffSearchBar}
                    openSearchBar={turnOnSearchBar}
                  />
                </StyledGrid>
              </Hidden>
            )}
            <StyledGrid item>
              <StyledGrid
                className="header-topbarSection"
                container
                alignItems="center"
                justifyContent="flex-end">
                <Hidden xsDown>
                  {mySite != null && (
                    <StyledGrid
                      item
                      className="header-branding"
                      data-espotname={headerConfig.espot.eSpotNameSecondLogo}>
                      <ContentRecommendationWidget {...crwPropsForRightLogo} />
                    </StyledGrid>
                  )}
                </Hidden>
                {!isCheckOut && (
                  <Hidden smUp>
                    {loggedIn ? (
                      <StyledGrid item>
                        <StyledButton
                          ref={myAccountElRef}
                          variant="text"
                          color="secondary"
                          className="header-actionsButton"
                          onClick={handleMyAccountClick}
                          testId="heaher-action-account">
                          <StyledHeaderActions>
                            <PersonIcon />
                          </StyledHeaderActions>
                        </StyledButton>
                        <StyledAccountPopper
                          id={myAccountPopperId}
                          open={myAccountPopperOpen}
                          anchorEl={myAccountElRef.current}
                          onClose={handleMyAccountPopperClose}
                          placement={"bottom-end"}
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "scrollParent",
                            },
                            hide: {
                              enabled: true,
                            },
                          }}
                          className="account-popper">
                          <ClickAwayListener
                            onClickAway={handleMyAccountPopperClose}>
                            <StyledPaper className="horizontal-padding-2">
                              <StyledTypography variant="body1" component="div">
                                <AccountPopperContent
                                  handleClose={handleMyAccountPopperClose}
                                  handleOrgChange={handleOrgChange}
                                  handleContractChange={handleContractChange}
                                  handleLogout={handleLogout}
                                  stipendEspot={stipendEspot}
                                  isB2B={isB2B}
                                  userName={{ firstName, lastName }}
                                />
                              </StyledTypography>
                            </StyledPaper>
                          </ClickAwayListener>
                        </StyledAccountPopper>
                      </StyledGrid>
                    ) : (
                      <StyledGrid item>
                        <StyledButton
                          className="header-actionsButton"
                          variant="text"
                          component={Link}
                          to={ROUTES.SIGNIN}
                          color="secondary"
                          testId="sign-in-button">
                          <StyledHeaderActions>
                            <PersonIcon />
                          </StyledHeaderActions>
                        </StyledButton>
                      </StyledGrid>
                    )}
                    {!isCheckOut && isShoppingEnabled && (
                      <StyledGrid item>
                        <StyledButton
                          className="header-actionsButton"
                          variant="text"
                          color="secondary"
                          ref={mobileMiniCartElRef}
                          onClick={handleMiniCartClick}>
                          <StyledHeaderActions>
                            <ShoppingCartIcon />
                          </StyledHeaderActions>
                        </StyledButton>
                      </StyledGrid>
                    )}
                  </Hidden>
                )}
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledContainer>
        {!isCheckOut && (
          <Hidden smUp>
            <StyledContainer className="mobile-search-bar">
              <SearchBar
                showSearchBar={showSearchBar}
                closeSearchBar={turnOffSearchBar}
                openSearchBar={turnOnSearchBar}
              />
            </StyledContainer>
          </Hidden>
        )}

        {!isCheckOut && (
          <Hidden smDown>
            <ExpandedMenu pages={topCategories}></ExpandedMenu>
          </Hidden>
        )}
        <StyledSwipeableDrawer
          anchor="left"
          open={open}
          onClose={turnOffOpen}
          onOpen={turnOnOpen}
          className="header-menu"
          data-testid="header-menu-drawer-element">
          <StyledContainer>
            <StyledGrid
              container
              className={"menu-container " + (open ? "open" : "")}>
              {!isCheckOut && (
                <IconButton
                  aria-label="close menu"
                  className="close"
                  onClick={turnOffOpen}>
                  <CloseIcon />
                </IconButton>
              )}
              {!isCheckOut && (
                <MegaMenu
                  menutitle={t("MegaMenu.Title")}
                  pages={topCategories}
                  closeMegaMenu={turnOffOpen}
                />
              )}
              {!isCheckOut && (
                <StyledTypography
                  variant="body1"
                  component="ul"
                  onClick={turnOffOpen}
                  data-espotname={headerConfig.espot.eSpotNameTollFreeMobile}
                  className="content-menu">
                  <li>
                    <Link to={ROUTES.CONTACT_US}>Contact &amp; Help</Link>
                  </li>
                </StyledTypography>
              )}

              <ContentRecommendationWidget {...crwPropsTollFreeMobile} />
            </StyledGrid>
          </StyledContainer>
        </StyledSwipeableDrawer>
      </StyledHeader>
    </>
  );
};

export { Header };
