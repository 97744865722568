/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { SUCCESS_MSG_PREFIX } from "../constants/common";
import { N_ITEMS_ADDED } from "../constants/order";
//Redux
import * as ORDER_ACTIONS from "../redux/action-types/order";

//customized to perform tax calculation only during checkout
export const ORDER_CONFIGS = {
  calculationUsage: "-1,-2,-4,-5,-6,-7",
  calculateOrder: "1",
  inventoryValidation: true,
  allocate: "***",
  backOrder: "***",
  remerge: "***",
  check: "*n",
  sortOrderItemBy: "orderItemID",
};

//customized to perform tax calculation only during checkout
export const ORDER_CONFIG_WITH_TAXES = {
  ...ORDER_CONFIGS,
  calculationUsage: "-1,-2,-3,-4,-5,-6,-7",
};

//customization: used when explicit calculations are needed to ensure correct taxes
export const CALCULATE_ORDER_CONFIGS_WITH_TAXES = {
  calculationUsage: [-1, -2, -3, -4, -5, -6, -7],
  doPrice: "Y",
  doFreeGift: "Y",
  updatePrices: "1",
};

export const PAGINATION_CONFIGS = {
  pageLimit: 10,
  pageSizeOptions: [10, 25, 50],
};

export const MINICART_CONFIGS = {
  maxItemsToShow: 3,
  itemAddSuccessMsgKeys: [
    SUCCESS_MSG_PREFIX + ORDER_ACTIONS.ITEM_ADD_SUCCESS,
    SUCCESS_MSG_PREFIX + ORDER_ACTIONS.COPY_CART_SUCCESS,
    SUCCESS_MSG_PREFIX + N_ITEMS_ADDED,
  ],
};

export const PAYMENT_CONFIGS = {
  maxNumPayment: 3,
};
