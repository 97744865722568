/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2023
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import Axios, { AxiosRequestConfig, Method, AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../../hooks/useSite";

const catalogEntryExtService = {
  /**
   * Finds SEO URL for a catalog entry by partNumber (either item or product)
   * `@method`
   * `@name CatalogEntryExt#getSeoUrlByPartNumber`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} partNumber (required)` The child property of `Parameters`.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  getSeoUrlByPartNumber(
    parameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    const site = getSite();
    let siteContext: string = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/catalog/catalogEntryExt/seoUrl/{partNumber}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const form: any = {};
    let body = {};
    let header: Headers;
    const queryParameters = new URLSearchParams();
    const formParams = new URLSearchParams();
    if (typeof headers === "undefined" || headers === null) {
      header = new Headers();
    } else {
      header = new Headers(headers);
    }
    if (parameters === undefined) {
      parameters = {};
    }
    if (parameters["storeId"] === undefined && site !== null) {
      parameters["storeId"] = site.storeID;
    }
    const headerValues: any = {};
    headerValues["Accept"] = [
      "application/json",
      "application/xml",
      "application/xhtml+xml",
      "application/atom+xml",
    ];
    for (const val of headerValues["Accept"]) {
      header.append("Accept", val);
    }
    if (parameters["storeId"] === undefined) {
      throw new Error(
        "Request '/store/{storeId}/catalog/catalogEntryExt/seoUrl/{partNumber}' missing path parameter storeId"
      );
    }
    requestUrl = requestUrl.replace("{storeId}", parameters["storeId"]);

    if (parameters["partNumber"] === undefined) {
      throw new Error(
        "Request '/store/{storeId}/catalog/catalogEntryExt/seoUrl/{partNumber}' missing path parameter partNumber"
      );
    }
    requestUrl = requestUrl.replace("{partNumber}", parameters["partNumber"]);

    if (parameters["responseFormat"] !== undefined) {
      const name = "responseFormat";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter);
        }
      });
    }
    if (!header.get("Content-Type")) {
      header.append("Content-Type", "application/json; charset=utf-8");
    }
    const accept = header.get("Accept");
    if (accept !== null && accept.indexOf("application/json") > -1) {
      header.set("Accept", "application/json");
    }
    if (
      header.get("content-type") === "multipart/form-data" &&
      Object.keys(form).length > 0
    ) {
      const formData = new FormData();
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name);
        } else {
          formData.append(p, form[p]);
        }
      }
      body = formData;
    } else if (Object.keys(form).length > 0) {
      header.set("content-type", "application/x-www-form-urlencoded");
      for (const p in form) {
        formParams.append(p, form[p]);
      }
      formParams.sort();
      body = formParams;
    }
    const headerObject: any = {};
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1];
    }
    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    );

    return Axios.request(requestOptions);
  },

  /**
   * Finds manufacturer name and model number for a catalog entry by catentryId
   * `@method`
   * `@name CatalogEntryExt#getByCatentryId`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {integer} catentryId (required)` The child property of `Parameters`.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  getByCatentryId(
    parameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    const site = getSite();
    let siteContext: string = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/catalog/catalogEntryExt/{catentryId}";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const form: any = {};
    let body = {};
    let header: Headers;
    const queryParameters = new URLSearchParams();
    const formParams = new URLSearchParams();
    if (typeof headers === "undefined" || headers === null) {
      header = new Headers();
    } else {
      header = new Headers(headers);
    }
    if (parameters === undefined) {
      parameters = {};
    }
    if (parameters["storeId"] === undefined && site !== null) {
      parameters["storeId"] = site.storeID;
    }
    const headerValues: any = {};
    headerValues["Accept"] = [
      "application/json",
      "application/xml",
      "application/xhtml+xml",
      "application/atom+xml",
    ];
    for (const val of headerValues["Accept"]) {
      header.append("Accept", val);
    }
    if (parameters["storeId"] === undefined) {
      throw new Error(
        "Request '/store/{storeId}/catalog/catalogEntryExt/{catentryId}' missing path parameter storeId"
      );
    }
    requestUrl = requestUrl.replace("{storeId}", parameters["storeId"]);

    if (parameters["catentryId"] === undefined) {
      throw new Error(
        "Request '/store/{storeId}/catalog/catalogEntryExt/{catentryId}' missing path parameter catentryId"
      );
    }
    requestUrl = requestUrl.replace("{catentryId}", parameters["catentryId"]);

    if (parameters["responseFormat"] !== undefined) {
      const name = "responseFormat";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter);
        }
      });
    }
    if (!header.get("Content-Type")) {
      header.append("Content-Type", "application/json; charset=utf-8");
    }
    const accept = header.get("Accept");
    if (accept !== null && accept.indexOf("application/json") > -1) {
      header.set("Accept", "application/json");
    }
    if (
      header.get("content-type") === "multipart/form-data" &&
      Object.keys(form).length > 0
    ) {
      const formData = new FormData();
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name);
        } else {
          formData.append(p, form[p]);
        }
      }
      body = formData;
    } else if (Object.keys(form).length > 0) {
      header.set("content-type", "application/x-www-form-urlencoded");
      for (const p in form) {
        formParams.append(p, form[p]);
      }
      formParams.sort();
      body = formParams;
    }
    const headerObject: any = {};
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1];
    }
    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    );

    return Axios.request(requestOptions);
  },

  /**
   * Finds manufacturer name and model number for catalog entries by catentryIds
   * `@method`
   * `@name CatalogEntryExt#getListByCatentryIds`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} catentryIds (required)` The list of catentryIds
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  getListByCatentryIds(
    parameters: any,
    headers?: any,
    url?: string
  ): AxiosPromise<any> {
    const site = getSite();
    let siteContext: string = "";
    if (site) {
      siteContext = site.transactionContext || "";
    }
    const domain = url || siteContext;
    const path = "/store/{storeId}/catalog/catalogEntryExt/byCatentryIds";
    let requestUrl = domain + path;
    const method: Method = "GET";
    const form: any = {};
    let body = {};
    let header: Headers;
    const queryParameters = new URLSearchParams();
    const formParams = new URLSearchParams();
    if (typeof headers === "undefined" || headers === null) {
      header = new Headers();
    } else {
      header = new Headers(headers);
    }
    if (parameters === undefined) {
      parameters = {};
    }
    if (parameters["storeId"] === undefined && site !== null) {
      parameters["storeId"] = site.storeID;
    }
    const headerValues: any = {};
    headerValues["Accept"] = [
      "application/json",
      "application/xml",
      "application/xhtml+xml",
      "application/atom+xml",
    ];
    for (const val of headerValues["Accept"]) {
      header.append("Accept", val);
    }
    if (parameters["storeId"] === undefined) {
      throw new Error(
        "Request '/store/{storeId}/catalog/catalogEntryExt/byCatentryIds' missing path parameter storeId"
      );
    }
    requestUrl = requestUrl.replace("{storeId}", parameters["storeId"]);

    if (parameters["catentryIds"] === undefined) {
      throw new Error(
        "Request '/store/{storeId}/catalog/catalogEntryExt/byCatentryIds' missing required parameter catentryIds"
      );
    }
    if (parameters["catentryIds"] !== undefined) {
      const name = "catentryIds";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters["responseFormat"] !== undefined) {
      const name = "responseFormat";
      const parameter = parameters[name];
      delete parameters[name];
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value);
        });
      } else {
        queryParameters.set(name, parameter);
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter);
        }
      });
    }
    if (!header.get("Content-Type")) {
      header.append("Content-Type", "application/json; charset=utf-8");
    }
    const accept = header.get("Accept");
    if (accept !== null && accept.indexOf("application/json") > -1) {
      header.set("Accept", "application/json");
    }
    if (
      header.get("content-type") === "multipart/form-data" &&
      Object.keys(form).length > 0
    ) {
      const formData = new FormData();
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name);
        } else {
          formData.append(p, form[p]);
        }
      }
      body = formData;
    } else if (Object.keys(form).length > 0) {
      header.set("content-type", "application/x-www-form-urlencoded");
      for (const p in form) {
        formParams.append(p, form[p]);
      }
      formParams.sort();
      body = formParams;
    }
    const headerObject: any = {};
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1];
    }
    queryParameters.sort();
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    );

    return Axios.request(requestOptions);
  },
};

export default catalogEntryExtService;
