/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { CONSTANTS } from "../../constants/order-item-table";
import FormattedPriceDisplay from "../../components/widgets/formatted-price-display";
import { INVENTORY_STATUS } from "../../constants/order";
import { PAGINATION_CONFIGS } from "../../configs/order";
//Redux
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import {
  forUserIdSelector,
  loginStatusSelector,
  userIdSelector,
} from "../../redux/selectors/user";

//UI
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import CloseIcon from "@material-ui/icons/CloseOutlined";
import {
  StyledGrid,
  StyledTypography,
  StyledNumberInput,
  StyledIconButton,
  TableColumnDef,
  withCustomTableContext,
  CustomTable,
  useTableUtils,
  TableConstants,
  useCustomTable,
  ImageWithFallback,
} from "@hcl-commerce-store-sdk/react-component";

//GA360
import AsyncCall from "../../_foundation/gtm/async.service";
import { cartSelector } from "../../redux/selectors/order";
import { useWinDimsInEM } from "./use-win-dims-in-em";
import { STRING_TRUE, XS_MOBILE_W } from "../../constants/common";
import { get } from "lodash-es";
import Closed from "@material-ui/icons/ChevronRight";
import Open from "@material-ui/icons/ExpandMoreOutlined";
import storeUtil from "../../utils/storeUtil";
import { IMAGE_RES } from "../../configs/catalog";

const OpenDrawer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Closed />
      <StyledTypography variant="caption">
        {t("OrderItemTable.Labels.showAttrs")}
      </StyledTypography>
    </>
  );
};
const CloseDrawer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Open />
      <StyledTypography variant="caption">
        {t("OrderItemTable.Labels.hideAttrs")}
      </StyledTypography>
    </>
  );
};

const DetailPanel = ({ rowData, ...props }) => {
  const { attributes: rawData } = rowData;
  const cellStyle = { verticalAlign: "middle" };
  const { t } = useTranslation();
  const attrs = rawData?.filter((a) => STRING_TRUE === a.displayable);

  // generate headers array
  const columns = attrs?.map((a, i) => ({
    title: a.name,
    idProp: "name",
    keyLookup: { key: `attr_${i}_value` },
    display: { cellStyle },
  }));

  // generate single row out of all attribute values
  const data = [
    attrs?.reduce((n, v, i) => {
      n[`attr_${i}_value`] = storeUtil.csValue(
        get(v, "values[0].value", t("CommerceEnvironment.inventoryStatus.NA"))
      );
      return n;
    }, {}),
  ];

  const className = "detailPanel table-tablet";
  const style = { width: "auto", border: "0" };
  const D = useMemo(() => withCustomTableContext(CustomTable), []);
  return columns?.length > 0 ? (
    <D
      {...{
        t,
        data,
        columns,
        style,
        className,
        outerClassName: "order-item-table-drawer",
        labels: { emptyMsg: "InprogressItems.noItems" },
      }}
    />
  ) : null;
};

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
export const useOrderItemTable = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mySite } = useSite();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const contractId = useSelector(currentContractIdSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;
  const dataProps = props.data;
  const { tableState, setTableState } = useCustomTable();
  const { setValueForCell } = useTableUtils();
  const [actionData, setActionData] = useState<any>(null);
  /**
   * Initialize table data by making a copy
   * Material-table alters the input data, so data cannot be of immutable type
   * @returns Copy of the data prop
   */
  const data = useMemo(() => {
    const newData = (dataProps ?? []).map((oi) => ({ ...oi }));
    return newData;
  }, [dataProps]);
  const hideAvailability =
    props.hideAvailability !== undefined ? props.hideAvailability : false;
  const showInventoryPlainValue =
    props.showInventoryPlainValue !== undefined
      ? props.showInventoryPlainValue
      : false;
  const orderHistoryDetailsStipendPriceType =
    props.orderHistoryDetailsStipendPriceType;
  const readOnly = props.readOnly !== undefined ? props.readOnly : true;
  const miniCartView =
    props.miniCartView !== undefined ? props.miniCartView : false;
  const galetonShipmentGroup =
    props.galetonShipmentGroup !== undefined
      ? props.galetonShipmentGroup
      : false;

  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  //update this flag as need later
  const pagination = !readOnly && !miniCartView;
  const handleMiniCartClose =
    props.handleMiniCartClose !== undefined ? props.handleMiniCartClose : null;

  /**
   * Initialize quantity data per order item
   * @returns quantities object for each order item
   */
  const initQuantityData = () => {
    const newData: any = {};
    if (dataProps) {
      dataProps.map((oi) => {
        if (oi.quantity) {
          try {
            const parsedQty = parseInt(oi.quantity);
            if (parsedQty > 0) {
              newData[oi.orderItemId] = parsedQty;
            }
          } catch (e) {
            console.log("Could not parse quantity");
          }
        }
        return null;
      });
    }
    return newData;
  };
  const [quantityList, setQuantityList] = useState<any>(initQuantityData());
  const defaultOptions = {
    toolbar: false,
    header: !miniCartView,
    paging: pagination,
    pageSize: PAGINATION_CONFIGS.pageLimit,
    pageSizeOptions: PAGINATION_CONFIGS.pageSizeOptions,
    actionsColumnIndex: -1,
    fixedColumns: {
      left: 0,
      right: 0,
    },
  };
  const options = props.options !== undefined ? props.options : defaultOptions;

  const InventoryDelayMessage = (props: any) => {
    let showMessage: boolean = false;
    if (props.rowData.orderItemExtendAttribute) {
      props.rowData.orderItemExtendAttribute.forEach(function (attr) {
        if (attr.attributeName === "SHOW_INV_DELAY_MESSAGE") {
          if (attr.attributeValue === "true") {
            showMessage = true;
          } else if (attr.attributeValue === "false") {
            showMessage = false;
          }
        }
      });
    }
    return (
      <>
        {showMessage && (
          <StyledTypography variant="body2" color="error">
            {t("OrderItemTable.Labels.InventoryDelay")}
          </StyledTypography>
        )}
      </>
    );
  };

  const columns = useMemo(
    () => {
      const cancels: Canceler[] = [];
      const payloadBase: any = {
        currency: defaultCurrencyID,
        contractId: contractId,
        widget: "Order Item Table",
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      const itemValCalc = ({ rowData: r }) => r.name || r.partNumber;
      const inventoryMessage = (r) => {
        let showDelayMessage: boolean | null = null;
        if (showInventoryPlainValue) {
          return r.orderItemInventoryStatus;
        } else {
          if (r.orderItemExtendAttribute) {
            r.orderItemExtendAttribute.forEach(function (attr) {
              if (attr.attributeName === "SHOW_INV_DELAY_MESSAGE") {
                if (attr.attributeValue === "true") {
                  showDelayMessage = true;
                } else if (attr.attributeValue === "false") {
                  showDelayMessage = false;
                }
              }
            });
          }
          if (showDelayMessage !== null) {
            return showDelayMessage
              ? t("CommerceEnvironment.inventoryStatus.OOS")
              : t("CommerceEnvironment.inventoryStatus.Available");
          } else {
            return r.availableDate === ""
              ? r.orderItemInventoryStatus === INVENTORY_STATUS.available ||
                r.orderItemInventoryStatus === INVENTORY_STATUS.allocated
                ? t("CommerceEnvironment.inventoryStatus.Available")
                : t("CommerceEnvironment.inventoryStatus.OOS")
              : r.availableDate <= new Date()
              ? t("CommerceEnvironment.inventoryStatus.Available")
              : r.orderItemInventoryStatus !== INVENTORY_STATUS.backordered
              ? t("CommerceEnvironment.inventoryStatus.Available")
              : t("CommerceEnvironment.inventoryStatus.Backordered");
          }
        }
      };
      const oaValCalc = ({ rowData: r }) => {
        return inventoryMessage(r);
      };
      const priceCalc = ({ rowData: r }) => Number(r.orderItemPrice);
      const quantityCalc = ({ rowData: r }) =>
        Number(quantityList[r.orderItemId]);

      const QuantityDisplay = (props: any) => (
        <StyledTypography>
          {quantityList[props.rowData.orderItemId]}
        </StyledTypography>
      );

      const OrderItemPrice = (props: any) => {
        let hasStipend: boolean = false;
        let stipendShowFullPrice: boolean | null = null;
        let stipendPrice: number | null = null;
        let orderItemPrice: number | null = null;

        if (props.rowData.orderItemExtendAttribute) {
          props.rowData.orderItemExtendAttribute.forEach(function (attr) {
            if (attr.attributeName === "STIPEND_SHOW_FULL_PRICE") {
              if (attr.attributeValue === "true") {
                stipendShowFullPrice = true;
              } else if (attr.attributeValue === "false") {
                stipendShowFullPrice = false;
              }
            } else if (attr.attributeName === "STIPEND_DISPLAY_PRICE") {
              stipendPrice = parseFloat(attr.attributeValue);
            }
          });
        }

        if (stipendShowFullPrice !== null && typeof stipendPrice === "number") {
          hasStipend = true;
          orderItemPrice = stipendPrice;
        } else {
          orderItemPrice = props.rowData.orderItemPrice
            ? parseFloat(props.rowData.orderItemPrice)
            : null;
        }

        if (orderHistoryDetailsStipendPriceType === "all") {
          return <StyledTypography align="inherit">Stipend</StyledTypography>;
        } else if (orderHistoryDetailsStipendPriceType === "part") {
          return (
            <StyledTypography align="inherit">
              Your stipend covered a portion of this item
            </StyledTypography>
          );
        } else {
          return (
            <StyledTypography align={miniCartView ? "right" : "inherit"}>
              {hasStipend && !stipendShowFullPrice && <>Stipend + </>}
              <FormattedPriceDisplay
                min={orderItemPrice}
                currency={props.rowData.currency}
              />
            </StyledTypography>
          );
        }
      };

      const ThumbnailCell = ({ rowData, ...props }) => {
        return (
          <>
            {rowData.seo && rowData.seo.href ? (
              <Link
                to={rowData.seo?.href}
                onClick={handleMiniCartClose ? handleMiniCartClose : null}>
                <div className="order-item-thumbnail">
                  <ImageWithFallback
                    alt={rowData.name}
                    src={`${mySite?.imagePath}${IMAGE_RES.thumbnail}${rowData.thumbnail}`}
                    srcSet={`${mySite?.imagePath}${IMAGE_RES.thumbnail}${rowData.thumbnail}, ${mySite?.imagePath}${IMAGE_RES.thumbnail2x}${rowData.thumbnail} 2x`}
                  />
                </div>
              </Link>
            ) : (
              <div className="order-item-thumbnail">
                <ImageWithFallback
                  alt={rowData.name}
                  src={`${mySite?.imagePath}${IMAGE_RES.thumbnail}${rowData.thumbnail}`}
                  srcSet={`${mySite?.imagePath}${IMAGE_RES.thumbnail}${rowData.thumbnail}, ${mySite?.imagePath}${IMAGE_RES.thumbnail2x}${rowData.thumbnail} 2x`}
                />
              </div>
            )}
          </>
        );
      };

      const QuantityCell = ({ rowData, ...props }) => {
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const cart = useSelector(cartSelector);
        const itemMemberId = rowData.xitem_memberId;
        const { w } = useWinDimsInEM();
        const mobile = !miniCartView && w > XS_MOBILE_W ? true : undefined;
        const disabled =
          rowData.freeGift === "true" ||
          (cart?.buyerId !== userId && userId !== itemMemberId);

        /**
         * Dispatch quantity update action for order item
         * @param item The selected order item
         */
        const onQuantityUpdate = (quantityString: string, item: any) => {
          if (item) {
            try {
              const quantity = parseInt(quantityString);
              if (quantity > 0) {
                const payload = {
                  ...payloadBase,
                  quantity: quantity.toString(),
                  orderItemId: item.orderItemId,
                  fetchCatentries: true,
                };
                dispatch(orderActions.UPDATE_ITEM_ACTION(payload));

                // update this (even tho it's temporary) just so that once the dispatch
                //   action completes, we don't "temporarily" revert back to the old value
                quantityList[rowData.orderItemId] = quantity;
              }
            } catch (e) {
              console.log("Could not parse quantity");
            }
          }
        };
        return miniCartView || readOnly ? (
          <QuantityDisplay rowData={rowData} />
        ) : (
          <StyledNumberInput
            mobile={mobile}
            value={quantityList[rowData.orderItemId]}
            min={1}
            step={1}
            precision={0}
            disabled={disabled}
            onChange={(event) => onQuantityUpdate(event, rowData)}
            stopLoadingOnUpdateValue={rowData}
            debounceTiming={250}
            strict
          />
        );
      };

      const ItemDetailsCell = ({ rowData, ...props }) => {
        return (
          <>
            <StyledGrid
              container
              justifyContent="space-between"
              wrap={miniCartView ? "nowrap" : undefined}>
              <StyledGrid item>
                <StyledTypography
                  variant="body1"
                  style={{ wordBreak: "break-word" }}>
                  {rowData.seo && rowData.seo.href ? (
                    <Link to={rowData.seo?.href} onClick={handleMiniCartClose}>
                      {rowData.name ? rowData.name : rowData.partNumber}
                    </Link>
                  ) : rowData.name ? (
                    rowData.name
                  ) : (
                    rowData.partNumber
                  )}
                </StyledTypography>
                {rowData.name && (
                  <StyledTypography variant="body1">
                    {!miniCartView && t("OrderItemTable.Labels.SKU")}
                    {rowData.partNumber}
                  </StyledTypography>
                )}
                {!miniCartView &&
                  rowData.attributes &&
                  rowData.attributes.map(
                    (attribute: any, index: number) =>
                      attribute.usage === "Defining" &&
                      attribute.values &&
                      attribute.values.map((value: any, index: number) => (
                        <StyledTypography variant="body1" key={value.id}>
                          {attribute.name}: {value.value}
                        </StyledTypography>
                      ))
                  )}
                {rowData.freeGift === "true" && (
                  <StyledTypography variant="overline" color="textSecondary">
                    {t("OrderItemTable.Labels.Gift")}
                  </StyledTypography>
                )}
                {!miniCartView && !hideAvailability && (
                  <InventoryDelayMessage rowData={rowData} />
                )}
                {!miniCartView &&
                  isRecurringOrderFeatureEnabled &&
                  rowData.disallowRecurringOrder === "1" && (
                    <StyledTypography variant="overline" color="textSecondary">
                      {t("OrderItemTable.Labels.NonRecurring")}
                    </StyledTypography>
                  )}
              </StyledGrid>
              {/*miniCartView && (
                <StyledGrid item>
                  <DeleteActionCell {...{ rowData }} />
                </StyledGrid>
              )*/}
            </StyledGrid>
            {miniCartView && (
              <StyledGrid container className="qty-price-section">
                <StyledGrid item xs={6}>
                  <QuantityCell {...{ rowData }} />
                </StyledGrid>
                <StyledGrid item xs={6}>
                  <OrderItemPrice rowData={rowData} />
                </StyledGrid>
              </StyledGrid>
            )}
          </>
        );
      };

      const DeleteActionCell = ({ rowData, ...props }) => {
        const cart = useSelector(cartSelector);
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const itemMemberId = rowData.xitem_memberId;
        const [clicked, setClicked] = useState<boolean>(false);
        const disabled =
          clicked ||
          rowData.freeGift === "true" ||
          (cart?.buyerId !== userId && userId !== itemMemberId);

        /**
         * Dispatch action to remove selected order item
         * @param item The selected order item
         */
        const removeItem = (item: any) => {
          const orderItemId = item.orderItemId;
          const payload = {
            ...payloadBase,
            orderItemId: orderItemId,
            fetchCatentries: true,
          };
          setClicked(true);
          dispatch(orderActions.REMOVE_ITEM_ACTION(payload));

          //GA360
          if (mySite.enableGA) {
            AsyncCall.sendRemoveFromCartEvent(item, {
              enableUA: mySite.enableUA,
              enableGA4: mySite.enableGA4,
            });
          }
        };

        return (
          <>
            {rowData.freeGift !== "true" && (
              <StyledIconButton
                disabled={disabled}
                aria-label="delete"
                onClick={() => removeItem(rowData)}>
                {miniCartView ? (
                  <CloseIcon />
                ) : (
                  <DeleteOutlineIcon className="delete-icon" />
                )}
              </StyledIconButton>
            )}
          </>
        );
      };

      const GaletonInStockCell = ({ rowData, ...props }) => {
        const inStock = rowData.orderItemExtendAttribute?.filter(
          (attr) => attr.attributeName === "INV_IN_STOCK"
        )[0]?.attributeValue;
        // 1 is Y, everything else is N
        const inStockDisplay = inStock === "1" ? "Y" : "N";
        return <StyledTypography>{inStockDisplay}</StyledTypography>;
      };

      const GaletonShipsWithinCell = ({ rowData, ...props }) => {
        const leadTime = rowData.orderItemExtendAttribute?.filter(
          (attr) => attr.attributeName === "INV_LEAD_TIME"
        )[0]?.attributeValue;
        // always returned in days
        return leadTime ? (
          <StyledTypography>{leadTime}</StyledTypography>
        ) : (
          <StyledTypography>N/A</StyledTypography>
        );
      };

      let columns: TableColumnDef[] = [
        {
          title: "",
          idProp: "orderItemId",
          keyLookup: {
            key: CONSTANTS.thumbnail,
          },
          display: {
            cellStyle: {
              textAlign: "center",
            },
            template: ThumbnailCell,
          },
        },
        {
          title: t("OrderItemTable.Labels.ItemDetails"),
          keyLookup: {
            key: CONSTANTS.name,
          },
          sortable: { fn: itemValCalc },
          display: {
            template: ItemDetailsCell,
          },
        },
        ...(!hideAvailability
          ? [
              {
                title: t("OrderItemTable.Labels.Status"),
                keyLookup: {
                  key: CONSTANTS.orderItemInventoryStatus,
                },
                sortable: { fn: oaValCalc },
                display: {
                  template: ({ rowData, ...props }) => (
                    <>{inventoryMessage(rowData)}</>
                  ),
                },
              },
            ]
          : []),

        {
          title: t("OrderItemTable.Labels.Quantity"),
          keyLookup: {
            key: CONSTANTS.quantity,
          },
          sortable: { numeric: true, fn: quantityCalc },
          display: {
            cellStyle: {
              textAlign: "left",
            },
            template: QuantityCell,
          },
        },
        {
          title: t("OrderItemTable.Labels.Price"),
          keyLookup: {
            key: CONSTANTS.orderItemPrice,
          },
          sortable: { numeric: true, fn: priceCalc },
          display: {
            cellStyle: {
              textAlign: "left",
            },
            template: ({ rowData, ...props }) => (
              <OrderItemPrice rowData={rowData} />
            ),
          },
        },
        {
          title: "Remove",
          keyLookup: {
            key: CONSTANTS.orderItemActions,
          },

          display: {
            cellStyle: {
              textAlign: "left",
            },
            template: DeleteActionCell,
          },
        },
      ];

      if (readOnly) {
        columns = columns.filter(
          (col) => col.keyLookup.key !== CONSTANTS.orderItemActions
        );
      }
      if (miniCartView) {
        columns = columns.filter(
          (col) =>
            col.keyLookup.key !== CONSTANTS.orderItemInventoryStatus &&
            col.keyLookup.key !== CONSTANTS.quantity &&
            col.keyLookup.key !== CONSTANTS.orderItemPrice &&
            col.keyLookup.key !== CONSTANTS.orderItemActions
        );
      }
      if (galetonShipmentGroup) {
        columns = columns.filter(
          (col) =>
            col.keyLookup.key !== CONSTANTS.orderItemPrice &&
            col.keyLookup.key !== CONSTANTS.orderItemInventoryStatus
        );
        // In Stock column
        const inStockColumn = {
          title: "In Stock",
          keyLookup: { key: CONSTANTS.galetonInStock },
          display: {
            template: GaletonInStockCell,
          },
        };
        // Ships Within column
        const shipsWithinColumn = {
          title: "Ships Within",
          keyLookup: { key: CONSTANTS.galetonShipsWithin },
          display: {
            template: GaletonShipsWithinCell,
          },
        };
        columns = [...columns, inStockColumn, shipsWithinColumn];
      }

      // hide panel for any records with not attributes
      data
        .filter((r) => !r.attributes?.length)
        .forEach((r) =>
          setValueForCell(
            TableConstants.NOPANEL,
            true,
            r,
            columns,
            tableState,
            () => {
              return;
            }
          )
        );

      setTableState({ ...tableState });

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      mySite,
      miniCartView,
      handleMiniCartClose,
      isRecurringOrderFeatureEnabled,
      contractId,
      dispatch,
      CancelToken,
      readOnly,
      quantityList,
      defaultCurrencyID,
      data,
    ]
  );

  const panelExpanderComps = useMemo(
    () => ({
      compShow: OpenDrawer,
      compHide: CloseDrawer,
    }),
    []
  );
  useEffect(() => {
    setQuantityList(initQuantityData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*useEffect(() => {
    if (seller) {
      setActionData({
        grids: [{ xs: true }],
        extraActions: [
          <div style={{ display: "flex", alignItems: "center" }}>
            <TruckIcon fontSize="large" className="right-margin-1" />
            <StyledTypography variant="h6">
              {t("productDetail.SellerSimple", { seller: seller.seller })}
            </StyledTypography>
          </div>,
        ],
      });
    }
  }, [seller]); // eslint-disable-line react-hooks/exhaustive-deps*/

  return {
    columns,
    data,
    options,
    detailPanel: DetailPanel,
    labels: {
      labelRowsSelect: t("OrderItemTable.Labels.PageSizeLabel"),
      labelDisplayedRows: t("OrderItemTable.Labels.RowCount"),
      firstTooltip: t("OrderItemTable.Labels.FirstPage"),
      previousTooltip: t("OrderItemTable.Labels.PreviousPage"),
      nextTooltip: t("OrderItemTable.Labels.NextPage"),
      lastTooltip: t("OrderItemTable.Labels.LastPage"),
    },
    t,
    miniCartView,
    handleMiniCartClose,
    panelExpanderComps,
    actionData,
  };
};
