/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
import {
  emeraldTheme as Emerald,
  sapphireTheme as Sapphire,
  divalTheme as GaletonESite,
} from "@hcl-commerce-store-sdk/react-component";
const themes = { Emerald, Sapphire, GaletonESite };
const CurrentTheme = themes[process.env.REACT_APP_STORENAME || "Emerald"];
export { CurrentTheme };
